.heroSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  min-height: calc(100vh);
}
.heroSection h1 {
  margin: 0px;
  text-align: center;
  /* text-transform: capitalize; */
  max-width: 80%;
  margin: 0 auto;
  line-height: 1;
}
.fs_110 {
  font-size: var(--fs-110);
}
/* .stroke_text{
  font-style: italic;
} */

.heroSection p {
  color: var(--clr-000000);
  text-align: center;
  max-width: 75%;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 1.3;
  font-size: 28px;
}
.heroSection .container {
  /* max-width: 70%; */
}
.heroSection .btnsDiv {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.heroSection .btnsDiv button:first-child {
  border: 2px solid var(--main-color);
}
.heroSection .btnsDiv button:last-child {
  background-color: transparent;
  color: var(--clr-000000);
  border: 2px solid var(--clr-000000);
}
/* .heroSection .btnsDiv button:last-child:hover {
  background-color: var(--main-color);
  border-color: var(--main-color);
} */
.heroSection .btnsDiv button:first-child:hover {
  background-color: transparent;
  color: var(--clr-000000);
  border: 2px solid var(--clr-000000);
}

.heroSection .btnsDiv button:last-child {
  position: relative;
  z-index: 1;
}
.heroSection .btnsDiv button:last-child:after {
  position: absolute;
  content: "";
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
  top: -2px;
  left: -2px;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  z-index: -1;
  transform: scaleX(0%) !important;
  transform-origin: right;
  transition: transform 250ms ease-in-out;
}
.heroSection .btnsDiv button:last-child:hover:after {
  transform: scaleX(100%) !important;
  transform-origin: left;
}

.heroSection {
  position: relative;
  --left: 0px;
  --top: 0px;
}
.heroSection:after {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 500px;
  left: var(--left);
  top: var(--top);
  border-radius: 50%;
  z-index: -1;
  /* background: radial-gradient(circle at 50% 50%,#b5ff15,#7ECA00, #97da060a); */
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  /* background: linear-gradient(-45deg, #ee7752, #dcff7b, #d8ff7a, #23d5ab); */
  background: linear-gradient(
    -45deg,
    #ee77526b,
    #dcff7b9c,
    #d8ff7a52,
    #23d5ab52
  );
  animation: gradient 15s ease infinite;
  filter: blur(100px);
  background-size: 100% 100%;
  /* 4c00ff00 */
  transform: translate(-50%, -50%);
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.imgDiv {
  width: 100%;
  height: 100%;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  object-position: 0;
}

.bgTrans {
  background-color: transparent !important;
}
@media screen and (max-width: 1024px) {
  .fs_110 {
    font-size: 90px;
  }
}
@media screen and (max-width: 768px) {
  .fs_110 {
    font-size: 75px;
  }
  .heroSection p {
    font-size: 26px;
  }
}

@media screen and (max-width: 600px) {
  .fs_110 {
    font-size: 66px;
  }
  .heroSection h1 {
    max-width: 90%;
  }
  .heroSection p {
    font-size: 22px;
    max-width: 85%;
  }
}
@media screen and (max-width: 540px) {
  .heroSection p {
    max-width: 100%;
    margin-top: 20px;
  }
  .heroSection h1 {
    max-width: 85%;
  }
  .fs_110 {
    font-size: 56px;
  }
}

@media screen and (max-width: 480px) {
  .heroSection h1 {
    max-width: 95%;
  }
}
@media screen and (max-width: 425px) {
  .heroSection .btnsDiv {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  .btnsDiv button {
    padding-inline: 0px;
    width: calc(50% - 10px);
  }
  .fs_110 {
    font-size: 50px;
  }
  .heroSection p {
    font-size: 18px;
    max-width: 90%;
  }
  .btnsDiv button {
    width: calc(36% - 10px);
  }
  .heroSection {
    min-height: calc(90vh);
  }
}

@media screen and (max-width: 375px) {
  .fs_110 {
    font-size: 40px;
  }
  .btnsDiv button {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .fs_110 {
    font-size: 38px;
  }
  .heroSection p {
    font-size: 16px;
  }
}
