
.card {
  padding: 30px 20px;
  overflow: hidden;
  border: 1px solid var(--main-color);
  border-color: #bdd030;
  height: 250px;
  background: #fff;
  transition: all linear 0.2s;
  border-radius: 5px;
  box-shadow: 1px 1px 22px 2px #455a1608;
  position: relative;
}
.card:hover{
  transition: all linear 0.2s;
  background: url(https://www.abtach.com/assets/images/what-we-do-hover.jpg) no-repeat left top;
    transform: scale(1.05);
    background-size: cover;

}
.card:hover>*{
  color: #fff;
}
.card h6{
  text-align: center;
  margin-top: 10px;
}
.card p {
  white-space: pre-wrap;
  width: 100%;
  margin-top: 10px;
}
.card span{
  background-color: #f0eeee;
  border-radius: 50%;
  display: block;
  width: max-content;
  padding: 14px;
  margin: 0 auto;
}
.card span svg{
  width: 25px;
  height: 25px;
  color: var(--text-black-color);
  display: inherit;
}