.bgTrans {
  background-color: transparent !important;
}

.workPage {
  padding-bottom: 7%;
}

.workPageHeader h1 {
  text-align: center;
  font-size: 168px;
}
.workPageHeader {
  padding-top: 15%;
}

.workPageHeader p {
  text-align: center;
  font-size: 40px;
  width: 60%;
  margin: 0 auto;
  line-height: 1.3;
  margin-top: 50px;
}

.projectCardList {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 5%;
}
.projectCard {
  width: 48%;
}

.largeProjectCard .imgDiv {
  height: 450px !important;
}
.projectCard {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 1280px) {
  .workPageHeader p {
    width: 65%;
  }
}

@media screen and (max-width: 1024px) {
  .workPageHeader p {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .workPageHeader {
    padding-top: 20%;
  }
  .workPageHeader p {
    font-size: 30px;
  }
  .workPageHeader h1 {
    font-size: 140px;
  }
  .workPage .projectCard {
    width: 100%;
  }
  .workPage .projectCard .details {
    bottom: 30px;
  }
  .workPage .projectCard > .details > h6 {
    font-size: 40px !important;
  }
  .workPage {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 600px) {
  .workPageHeader p {
    width: 95%;
  }
  .workPageHeader h1 {
    font-size: 125px;
  }
}

@media screen and (max-width: 540px) {
  .workPageHeader p {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .workPageHeader h1 {
    font-size: 100px;
  }
  .workPageHeader {
    padding-top: 30%;
  }
  .workPageHeader p {
    font-size: 24px;
  }
}

@media screen and (max-width: 425px) {
  .workPageHeader p {
    font-size: 22px;
  }
}

@media screen and (max-width: 375px) {
  .workPageHeader h1 {
    font-size: 80px;
  }
  .workPageHeader {
    padding-top: 40%;
  }
  .workPageHeader p {
    font-size: 18px;
  }
}

@media screen and (max-width: 320px) {
  .workPageHeader p {
    font-size: 16px;
  }
}
