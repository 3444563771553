.bgTrans {
  background-color: transparent !important;
  max-width: 100% !important;
}
.video {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.video > div {
  width: 100% !important;
  height: 700px !important;
}
.video video {
  object-fit: cover;
  object-position: 0;
}

@media screen and (max-width: 1220px) {
  .video video {
    object-fit: contain;
  }
}
