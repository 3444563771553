/* --main-color:#004a00c7; */

@font-face {
  font-family: f-bold;
  src: url(./assets/fonts/PPNeueMontreal/PPNeueMontreal-Medium.otf);
}
@font-face {
  font-family: f-reg;
  src: url(./assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.otf);
}

/*  */
:root {
  /* COLORS */
  --main-color: #b4ff15;
  --clr-ffffff: #ffffff;
  --clr-000000: #000000;
  --clr-121212: #121212;
  --clr-E5E5E5: #e5e5e5;
  --section-bg: #f6f9fc;
  --text-clr: var(--clr-121212);

  /* CUSTOM CONTAINER */
  --custom-container: 85%;

  /* FONT FAMILY */

  --ff-neue: "Neue";

  /* FONTS SIZES */

  --fs-110: 110px;
  --fs-90: 90px;
  --fs-80: 80px;
  --fs-70: 70px;
  --fs-60: 60px;
  --fs-50: 50px;
  --fs-40: 40px;
  --fs-34: 34px;
  --fs-30: 30px;
  --fs-28: 28px;
  --fs-24: 24px;
  --fs-22: 22px;
  --fs-20: 20px;
  --fs-18: 18px;
  --fs-16: 16px;
  --fs-14: 14px;
  --fs-12: 12px;

  /* FONT WEIGHT */

  --fw-light: 200;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
}

/* ----- FONT FAMILY ----- */

@font-face {
  font-family: "Neue";
  src: url(./assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.otf);
  font-weight: normal;
}

/* ----- GENERAL STYLING ----- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--ff-neue);
  /* position: relative; */
}

body {
  position: relative;
}

.custom_container {
  max-width: var(--custom-container);
  width: 100%;
  margin: 0 auto;
}

.blue_clr {
  color: var(--clr-003ECB);
}
.text_clr {
  color: var(--text-clr);
}
.white_clr {
  color: var(--clr-ffffff);
}
.blue_clr_bg {
  background-color: var(--clr-003ECB);
}
.text_clr {
  color: var(--text-clr);
}

.ff_neue {
  font-family: var(--ff-neue);
}

ul,
li {
  list-style: none;
}

h1 {
  font-size: var(--fs-90);
}
h2 {
  font-size: var(--fs-70);
}
h3 {
  font-size: var(--fs-50);
}
h4 {
  font-size: var(--fs-40);
}
h5 {
  font-size: var(--fs-34);
}
h6 {
  font-size: var(--fs-30);
}

p {
  font-size: var(--fs-16);
  letter-spacing: 0.5px;
  line-height: 1.7;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--text-clr);
}

.fw_regular {
  font-weight: var(--fw-regular) !important;
}
.fw-medium {
  font-weight: var(--fw-medium);
}
.fw-semibold {
  font-weight: var(--fw-semibold);
}
.fw-bold {
  font-weight: var(--fw-bold);
}

/* ============ HEADER CSS ============ */
ul.navbar-nav {
  width: 100%;
  justify-content: center;
}

.brand_logo {
  font-family: var(--ff-neue);
  font-size: var(--fs-30);
}
.brand_logo > b {
  font-weight: var(--fw-bold) !important;
}

/* ============ HERO SECTION STYLING ============ */

.hero_section {
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* border: 1px solid red; */
}

#cursor {
  width: 250px;
  height: 250px;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all ease-out 1s;
  /* border-radius: 50%; */
  border-radius: 30% 70% 68% 32% / 48% 44% 56% 52%;
  /* background-image: linear-gradient(to bottom, #fc00ff, #00dbde); */
  background-image: linear-gradient(to bottom, #4364f7, #a5fecb);
  /* background-image: linear-gradient(to bottom, #5433FF, #20BDFF,#A5FECB); */
  filter: blur(85px);
  z-index: -1;
  cursor: grab;
}

.btns_div {
  /* border: 1px solid red; */
  width: 25%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
a.blue_btn {
  background: var(--clr-003ECB);
  color: var(--clr-ffffff);
  padding: 10px 35px;
  border-radius: 3px;
  border: 2px solid var(--clr-003ECB);
}
a.bordered_btn {
  border: 2px solid var(--clr-003ECB);
  box-shadow: inset 0 0 0 0px var(--clr-003ECB);
  color: var(--clr-003ECB);
  padding: 10px 35px;
  border-radius: 3px;
  font-weight: var(--fw-bold);
  transition: ease-out 0.25s;
}
a.bordered_btn:hover {
  box-shadow: inset 150px 0 0 0 var(--clr-003ECB);
  color: var(--clr-ffffff);
  font-weight: var(--fw-medium);
}
a.nav-link {
  padding: 0 !important;
  margin-inline: 10px;
  position: relative;
  transition: ease-out 0.3s;
  letter-spacing: 0.5px;
}
a.link_hover:after {
  position: absolute;
  content: "";
  background: black;
  width: 0%;
  height: 1px;
  bottom: 0;
  left: 0;
  transition: ease-out 0.2s;
}

a.link_hover:hover:after {
  width: 100%;
}
.scroll_down {
  position: absolute;
  /* border: 1px solid var(--clr-000000); */
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  will-change: transform;
  animation: rotate_circle ease-out 15s;
  animation-play-state: running;
  animation-iteration-count: infinite;
  font-size: var(--fs-12);
}
.scroll_down object {
  width: 75%;
}

@keyframes rotate_circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ABOUT SECTION STYLING */
.about_section {
  padding-top: 7%;
  padding-bottom: 5%;
}

.about_section .title {
  font-size: 50px !important;
  width: 70%;
  margin-bottom: 8%;
}
.img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 75%;
  margin-left: auto;
  border-top-left-radius: 75px;
  border-bottom-right-radius: 75px;
  box-shadow: 15px 15px 0px 0px var(--clr-003ECB);
  overflow: hidden;
}
.bg_overlay {
  width: 100%;
  height: 100%;
  background: var(--clr-003ECB);
  position: absolute;
  opacity: 0.8;
  mix-blend-mode: color;
}
section {
  /* padding-block: 7%; */
}
.sectionHeading {
  font-size: 80px;
}
@media screen and (max-width: 1440px) {
  h1 {
    font-size: var(--fs-80);
  }
  h2 {
    font-size: var(--fs-70);
  }
  h3 {
    font-size: var(--fs-50);
  }
  h4 {
    font-size: var(--fs-40);
  }
  h5 {
    font-size: var(--fs-34);
  }
  h6 {
    font-size: var(--fs-30);
  }
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: var(--fs-70);
  }
  h2 {
    font-size: var(--fs-60);
  }
  h3 {
    font-size: var(--fs-50);
  }
  h4 {
    font-size: var(--fs-40);
  }
  h5 {
    font-size: var(--fs-34);
  }
  h6 {
    font-size: var(--fs-30);
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: var(--fs-60);
  }
  h2 {
    font-size: var(--fs-50);
  }
  h3 {
    font-size: var(--fs-40);
  }
  h4 {
    font-size: var(--fs-34);
  }
  h5 {
    font-size: var(--fs-30);
  }
  h6 {
    font-size: var(--fs-28);
  }
  .custom_container {
    max-width: 90%;
  }
}
@media screen and (max-width: 540px) {
  h1 {
    font-size: var(--fs-50);
  }
  h2 {
    font-size: var(--fs-40);
  }
  h3 {
    font-size: var(--fs-34);
  }
  h4 {
    font-size: var(--fs-30);
  }
  h5 {
    font-size: var(--fs-28);
  }
  h6 {
    font-size: var(--fs-24);
  }
}

@media screen and (max-width: 425px) {
  .custom_container {
    max-width: 94%;
  }
}
