.page {
  /* background-color: #f8f8f8; */
  overflow-x: hidden;
}
.container {
  max-width: 85%;
  margin: 0 auto;
}
.bgTrans {
  background-color: transparent !important;
}
.appear {
  transform: translate(0px);
}
.aboutSection {
  /* margin-block: 20px; */
  /* background-color: #f6f9fc; */
  padding-block: 7%;
}
.aboutSection h3 {
  margin: 0px;
  margin-bottom: 20px;
}
.aboutSection p {
  margin-bottom: 20px;
}

.contactSection {
  margin-block: 20px;
  padding: 20px 30px;
  /* background-color: #f6f9fc; */

  /* background: transparent linear-gradient(45deg, #004a0024, rgb(0 74 0 / 20%)); */
  /* border-radius: 20px; */
  /* box-shadow: 5px 5px 6px 0px rgb(39 39 39 / 26%); */
}
.imgDiv {
  width: 100%;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.aboutBigImg {
  width: 100%;
  height: 100% !important;
}

.imgOverlay {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}
.imgOverlay img {
  transform-origin: center;
  transition: transform 0.25s ease-in-out;
}
.imgOverlay:hover img {
  transform: scale(1.05);
}
.imgOverlay:after {
  position: absolute;
  content: "";
  background-image: linear-gradient(to top, #171817, #000000ba);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.imgOverlay:hover:after {
  opacity: 0;
  border-radius: 10px;
  filter: blur(0px);
  cursor: pointer;
}
.aboutSmallImg {
  margin-right: auto;
  height: 50%;
  width: 70%;
}
.smallImgCol .ant-row {
  display: flex;
  align-items: center;
  gap: 20px;
}
.smallImgCol {
  display: flex;
  flex-direction: column;
}

.aboutBigHeading {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 150px;
  display: block;
}

.aboutContent {
  /* text-align: right; */
}
.aboutContent h6 {
  margin-bottom: 20px;
}

.aboutSpan {
  font-size: 156px;
  /* background: black; */
  /* padding: 15px 50px; */
  color: #b4ff15;
  /* border-radius: 125px; */
  width: fit-content;
  display: block;
  position: relative;
  /* transform: rotateZ(-5deg); */
  transition: transform ease-out 12s;
  font-style: italic;
  text-decoration: underline;
}

.aboutSpan:hover {
  transition: transform ease-out 12s;
  cursor: pointer;
}
.aboutSpan {
  position: relative;
}
.aboutSpan:hover {
  color: black;
}
.aboutSpan:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #b4ff15;
  top: 0;
  left: 0;
  /* border-radius: 100px; */
  z-index: -1;
  transform: scaleX(0%);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}
.aboutSpan:hover:after {
  /* transform: scaleX(103%); */
}

.contactSection h5 {
  /* font-size: 35px;
  color: var(--main-color); */
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
}
.mt20 {
  margin-top: 20px;
}

/*  */
.reviewsSection {
  background-color: #f6f9fc;
  padding-block: 30px;
}
.reviewsSection h5 {
  /* font-size: 35px; */
  /* color: var(--text-black-color); */
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
}
.carouselImg {
  width: 100%;
  height: 300px;
}
.carouselImg img {
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
}

.pattern {
  --s: 25vmin;
  --p: calc(var(--s) / 2);
  --c1: rgb(220, 255, 124);
  --c2: rgb(120, 255, 30);
  --c3: white;
  --bg: var(--c3);
  --d: 4000ms;
  --e: cubic-bezier(0.76, 0, 0.24, 1);

  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--bg);
  background-image: linear-gradient(45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(-45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--c2) 75%),
    linear-gradient(-45deg, transparent 75%, var(--c2) 75%);
  background-size: var(--s) var(--s);
  background-position: calc(var(--p) * 1) calc(var(--p) * 0),
    calc(var(--p) * -1) calc(var(--p) * 1),
    calc(var(--p) * 1) calc(var(--p) * -1),
    calc(var(--p) * -1) calc(var(--p) * 0);
  animation: color var(--d) var(--e) infinite,
    position var(--d) var(--e) infinite;
}

.pattern h1 {
  font-size: 170px;
  text-align: center;
  font-weight: 400;
  color: #6eff31;
  transition: color 250ms ease-in-out;
}
.pattern h1:hover {
  color: var(--clr-000000);
  transition: color 250ms ease-in-out;
}

@keyframes color {
  0%,
  25% {
    --bg: var(--c3);
  }
  26%,
  50% {
    --bg: var(--c1);
  }
  51%,
  75% {
    --bg: var(--c3);
  }
  76%,
  100% {
    --bg: var(--c2);
  }
}

@keyframes position {
  0% {
    background-position: calc(var(--p) * 1) calc(var(--p) * 0),
      calc(var(--p) * -1) calc(var(--p) * 1),
      calc(var(--p) * 1) calc(var(--p) * -1),
      calc(var(--p) * -1) calc(var(--p) * 0);
  }
  25% {
    background-position: calc(var(--p) * 1) calc(var(--p) * 4),
      calc(var(--p) * -1) calc(var(--p) * 5),
      calc(var(--p) * 1) calc(var(--p) * 3),
      calc(var(--p) * -1) calc(var(--p) * 4);
  }
  50% {
    background-position: calc(var(--p) * 3) calc(var(--p) * 8),
      calc(var(--p) * -3) calc(var(--p) * 9),
      calc(var(--p) * 2) calc(var(--p) * 7),
      calc(var(--p) * -2) calc(var(--p) * 8);
  }
  75% {
    background-position: calc(var(--p) * 3) calc(var(--p) * 12),
      calc(var(--p) * -3) calc(var(--p) * 13),
      calc(var(--p) * 2) calc(var(--p) * 11),
      calc(var(--p) * -2) calc(var(--p) * 12);
  }
  100% {
    background-position: calc(var(--p) * 5) calc(var(--p) * 16),
      calc(var(--p) * -5) calc(var(--p) * 17),
      calc(var(--p) * 5) calc(var(--p) * 15),
      calc(var(--p) * -5) calc(var(--p) * 16);
  }
}
.bigImgCol {
}
@media screen and (max-width: 1100px) {
  .aboutSmallImg {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .aboutBigHeading {
    /* font-size: 118px; */
  }
  .aboutSpan {
    font-size: 134px;
  }
}
@media screen and (max-width: 992px) {
  .aboutBigHeading {
    /* font-size: 100px; */
    margin-bottom: 80px;
  }
  .aboutSpan {
    font-size: 90px;
  }
  .aboutSection {
    padding-block: 50px;
  }
  .dNone991 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .aboutBigHeading {
    /* font-size: 90px; */
    margin-bottom: 30px;
  }
  .aboutSpan {
    font-size: 130px;
    display: block;
  }

  .aboutSection {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .aboutBigImg {
    height: 250px !important;
  }
  .bigImgCol {
    /* margin-top: 20px; */
  }
}
@media screen and (max-width: 600px) {
  .aboutBigHeading {
    /* font-size: 70px; */
    margin-bottom: 30px;
  }
  .aboutSpan {
    font-size: 102px;
  }
}
@media screen and (max-width: 575px) {
  .aboutBigHeading {
    /* font-size: 60px; */
    margin-bottom: 20px;
  }
  .aboutSpan {
    font-size: 50px;
    display: block;
  }
  .aboutBigImg {
    height: 230px !important;
  }
}

@media screen and (max-width: 540px) {
  .aboutBigHeading {
    /* font-size: 65px; */
  }
  .aboutSpan {
    font-size: 90px;
  }
}

@media screen and (max-width: 480px) {
  .aboutBigHeading {
    /* font-size: 55px; */
  }
  .aboutSpan {
    font-size: 80px;
  }
}
@media screen and (max-width: 425px) {
  .aboutBigHeading {
    /* font-size: 50px; */
    margin-bottom: 20px;
  }
  .aboutSpan {
    font-size: 75px;
    display: block;
  }
}

@media screen and (max-width: 375px) {
  .aboutBigHeading {
    /* font-size: 44px; */
  }
  .aboutSpan {
    font-size: 65px;
  }
}

@media screen and (max-width: 320px) {
  .aboutBigHeading {
    /* font-size: 40px; */
  }
  .aboutSpan {
    font-size: 58px;
  }
}
