.header {
  padding: 0px !important;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  line-height: 64px;
  background-color: transparent !important;

  /* border-bottom: 1px solid var(--main-color); */
}
.heading {
  position: relative;
}
.heading span {
  position: absolute;
  left: 100%;
  font-size: 16px;
  top: -10px;
}
.boxShadow {
  box-shadow: 0px 0px 10px 0px #00000017;
  background-color: var(--clr-ffffff) !important;
}
.header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h1 {
  margin: 0px;
}
.links {
  background-color: transparent;
  margin-block: 15px;
  /* width: 100%; */
}
.sideLink {
  /* line-height: 34px; */
  padding: 12px 20px;
  line-height: 1;
  margin-bottom: 10px;
}
.link {
  line-height: 34px;
  border-radius: 0px !important;
}
.sideLink:hover {
  background: var(--main-color) !important;
}
.link {
  position: relative;
}
.link:after {
  position: absolute;
  content: "";
  background: var(--main-color) !important;
  width: 100%;
  height: 2px;
  left: 0 !important;
  bottom: 0 !important;
  transform: scaleX(0%) !important;
  transform-origin: center !important;
  transition: transform 200ms ease-in-out !important;
}
.link:not(.selected):hover:after {
  transform: scaleX(100%) !important;
  transform-origin: left right !important;
}
.link:after {
  border-bottom: none !important ;
}
/* .link.selected {
  border-bottom: 2px solid var(--main-color) !important;
} */
.selected {
  position: relative !important;
}
.selected::before {
  position: absolute !important;
  content: " " !important;
  width: 18px !important;
  height: 5px !important;
  background: var(--main-color) !important;
  display: block;
  bottom: 0;
  border-radius: 15px;
  left: 50%;
  transform: translateX(-50%);
}
.sideLink.selected {
  background-color: var(--main-color);
}

.btn {
  height: max-content;
  margin: auto 0;
}

/* Drawer */
.drawer {
  background-color: var(--clr-121212) !important;
}
.navLinks {
  display: flex;
  flex-direction: column;
}

.boxShadow {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0.7)
  ) !important;
}
.drawerHeader {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-ffffff);
  /* Created with https://www.css-gradient.com */
  /* Created with https://www.css-gradient.com */
  background-image: linear-gradient(
    160deg,
    hsl(79deg 100% 54%) 0%,
    hsl(79deg 100% 46%) 8%,
    hsl(81deg 100% 43%) 17%,
    hsl(84deg 100% 39%) 26%,
    hsl(86deg 100% 35%) 35%,
    hsl(90deg 100% 32%) 45%,
    hsl(96deg 100% 28%) 54%,
    hsl(102deg 100% 25%) 62%,
    hsl(114deg 100% 22%) 70%,
    hsl(120deg 100% 18%) 77%,
    hsl(120deg 100% 15%) 83%,
    hsl(118deg 100% 12%) 89%,
    hsl(108deg 100% 9%) 93%,
    hsl(120deg 100% 7%) 97%,
    hsl(0deg 0% 0%) 100%
  );
}
.drawer .sideLink {
  color: var(--clr-ffffff);
  border-bottom: 1px solid var(--main-color);
}
.drawer .selected {
  color: var(--clr-000000);
}
