.inputDiv {
  display: flex;
  flex-direction: column;
}
.inputDiv label {
  font-size: 16px;
  color: var(--text-black-color);
  margin-bottom: 8px;
  font-family: f-reg;
  font-weight: 500;
}
.inputDiv input,
.inputDiv textarea {
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #afb9d7a1;
  outline: none;
  resize: none;
}
.inputDiv input,
.inputDiv textarea {
  border-radius: 0px;
  border: 0;
  border-bottom: 1px solid lightgray !important;
}
