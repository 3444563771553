.btn{
    background:var(--main-color);
    color: var(--clr-000000);
    padding: 12px 25px;
    font-size: 15px;
    font-family: f-reg;
    outline: none;
    border: none;
    text-transform: uppercase;
    /* box-shadow: 2px 2px 4px 2px #0000003d; */
    transition: all .3s;
    cursor: pointer;
    /* border: 2px solid var(--main-color); */
    border-radius: 6px;

}
.btn:hover{
    /* animation-name: anim; */
    /* animation-duration: 2s; */
    /* animation-iteration-count: infinite; */

}
@keyframes anim {
    0%{
        background:transparent linear-gradient(90deg, #033603c4, #50810ebf);
        /* transform: scale(1.01); */
        
    }
    30%{
        /* background:transparent linear-gradient(90deg, var(--main-color), #50810e8c); */
        /* transform: scale(1.02); */
        


    }
    60%{
        background:transparent linear-gradient(90deg, #50810ea3, #033603c4);
        /* transform: scale(1.03); */
        


    }
    90%{
        background:transparent linear-gradient(90deg, #50810ea3,#033603c4);
        /* transform: scale(1.04); */
        


    }
}