.bgTrans {
  background-color: transparent !important;
}
.servicePage {
  padding-block: 5%;
}

.service_content h1 {
  font-weight: 400;
  font-size: 168px;
  margin-bottom: 15%;
  margin-top: 10%;
}

.service_content h2 {
  font-weight: 400;
}

.serviceIntroImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.serviceIntroImg {
  width: 100%;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
}

.goodAt {
  margin-top: 15%;
  text-align: center;
  margin-bottom: 5%;
}
.goodAt h2 {
  font-weight: 400;
  text-transform: uppercase;
}

@media screen and (max-width: 1920px) {
  .service_content h1 {
    font-size: 225px;
  }
  .service_content h2 {
    font-size: 90px;
    padding-right: 40px;
  }
  .goodAt h2 {
    font-size: 95px;
  }
  .ant-collapse-content-box p {
    font-size: 24px;
  }
  .serviceIntroImg {
    height: 650px;
  }
}

@media screen and (max-width: 1600px) {
  .service_content h1 {
    font-size: 190px;
  }
  .service_content h2 {
    font-size: 80px;
  }
}

@media screen and (max-width: 1536px) {
  .service_content h2 {
    font-size: 78px;
  }
}

@media screen and (max-width: 1366px) {
  .serviceIntroImg {
    height: 600px;
  }
  .service_content h2 {
    font-size: 72px;
  }
}

@media screen and (max-width: 1280px) {
  .service_content h1 {
    font-size: 170px;
  }
  .serviceIntroImg {
    height: 500px;
  }
  .service_content h2 {
    font-size: 64px;
  }
}

@media screen and (max-width: 1024px) {
  .service_content h1 {
    font-size: 150px;
  }
  .service_content h2 {
    font-size: 56px;
  }
  .goodAt h2 {
    font-size: 80px;
  }
  .ant-collapse-content-box p {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .service_content h2 {
    font-size: 54px;
  }
}

@media screen and (max-width: 800px) {
  .service_content h1 {
    font-size: 125px;
  }
  .service_content .row > div {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .service_content .row {
    gap: 80px;
  }
  .goodAt h2 {
    font-size: 70px;
  }
  .servicePage {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 600px) {
  .service_content h1 {
    font-size: 100px;
    margin-top: 30%;
  }
  .goodAt h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 540px) {
  .service_content h1 {
    font-size: 90px;
  }
  .service_content h2 {
    font-size: 50px;
  }
  .serviceIntroImg {
    height: 450px;
  }
}

@media screen and (max-width: 480px) {
  .service_content h1 {
    font-size: 80px;
  }
  .service_content h2 {
    font-size: 44px;
    padding-right: 0;
  }
}

@media screen and (max-width: 425px) {
  .service_content h1 {
    font-size: 75px;
  }
  .goodAt h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 375px) {
  .service_content h1 {
    font-size: 65px;
  }
  .service_content h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 320px) {
  .service_content h1 {
    font-size: 55px;
  }
  .goodAt h2 {
    font-size: 30px;
  }
  .serviceIntroImg {
    height: 350px;
  }
}
