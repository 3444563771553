.about_page {
  background: none !important;
  padding-block: 5%;
}
.about_page h5 {
  text-align: left;
  margin-top: 50px;
  font-weight: 500;
}
.bgTrans {
  background-color: transparent !important;
}
.about_content {
}
.about_content h1 {
  font-size: 168px;
  font-weight: 400;
  margin-top: 80px;
}
.about_content h1 > span {
  display: flex;
  align-items: center;
  width: max-content;
}
.about_content h5 {
  font-size: 75px;
  width: 920px;
  margin: 0 auto;
  text-align: center;
  margin-block: 120px;
  text-transform: uppercase;
}

.aboutImg1 {
  margin-bottom: 15%;
}
.about_img1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about_img1 {
  width: 100%;
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
}

/* .aboutMainContent {
  margin-top: 8%;
} */
.aboutMainContent h4 {
  font-weight: 400;
  width: 80%;
}

.twoParagraphs {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  width: 80%;
}

/* .aboutImg2 {
  margin-top: 10%;
} */
.about_img2 {
  width: 100%;
  height: 350px;
  border-radius: 20px;
  overflow: hidden;
}
.about_img2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.w100 {
}
.pattern {
  --s: 5vmin;
  --p: calc(var(--s) / 2);
  --c1: rgb(220, 255, 124);
  --c2: rgb(120, 255, 30);
  --c3: white;
  --bg: var(--c3);
  --d: 4000ms;
  --e: cubic-bezier(0.76, 0, 0.24, 1);

  width: 125px;
  height: 125px;
  border-radius: 125px;
  display: inline-block;
  border: 6px solid var(--c2);

  background-color: var(--bg);
  background-image: linear-gradient(45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(-45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--c2) 75%),
    linear-gradient(-45deg, transparent 75%, var(--c2) 75%);
  background-size: var(--s) var(--s);
  background-position: calc(var(--p) * 1) calc(var(--p) * 0),
    calc(var(--p) * -1) calc(var(--p) * 1),
    calc(var(--p) * 1) calc(var(--p) * -1),
    calc(var(--p) * -1) calc(var(--p) * 0);
  animation: 
    /* move var(--d) ease-in-out infinite, */ color var(--d)
      var(--e) infinite,
    position var(--d) var(--e) infinite;
}

/* @keyframes move {
  0%, 25% {
    width: calc(250px);
    height: calc(150px);
  }
  26%, 50% {
    width: calc(200px);
    height: calc(100px);
  }
  51%, 75% {
    width: calc(50px);
    height: calc(150px);
  }
  76%, 100% {
    width: calc(320px);
    height: calc(120px);
  }
} */

@keyframes color {
  0%,
  25% {
    --bg: var(--c3);
  }
  26%,
  50% {
    --bg: var(--c1);
  }
  51%,
  75% {
    --bg: var(--c3);
  }
  76%,
  100% {
    --bg: var(--c2);
  }
}

@keyframes position {
  0% {
    background-position: calc(var(--p) * 1) calc(var(--p) * 0),
      calc(var(--p) * -1) calc(var(--p) * 1),
      calc(var(--p) * 1) calc(var(--p) * -1),
      calc(var(--p) * -1) calc(var(--p) * 0);
  }
  25% {
    background-position: calc(var(--p) * 1) calc(var(--p) * 4),
      calc(var(--p) * -1) calc(var(--p) * 5),
      calc(var(--p) * 1) calc(var(--p) * 3),
      calc(var(--p) * -1) calc(var(--p) * 4);
  }
  50% {
    background-position: calc(var(--p) * 3) calc(var(--p) * 8),
      calc(var(--p) * -3) calc(var(--p) * 9),
      calc(var(--p) * 2) calc(var(--p) * 7),
      calc(var(--p) * -2) calc(var(--p) * 8);
  }
  75% {
    background-position: calc(var(--p) * 3) calc(var(--p) * 12),
      calc(var(--p) * -3) calc(var(--p) * 13),
      calc(var(--p) * 2) calc(var(--p) * 11),
      calc(var(--p) * -2) calc(var(--p) * 12);
  }
  100% {
    background-position: calc(var(--p) * 5) calc(var(--p) * 16),
      calc(var(--p) * -5) calc(var(--p) * 17),
      calc(var(--p) * 5) calc(var(--p) * 15),
      calc(var(--p) * -5) calc(var(--p) * 16);
  }
}
@media screen and (max-width: 1100px) {
  .about_content h5 {
    width: 100%;
  }
  .about_content h1 {
    font-size: 150px;
  }
  .pattern {
    width: 120px;
    height: 120px;
    border-radius: 120px;
  }
}
@media screen and (max-width: 1044px) {
  .about_content h5 {
    /* width: 90%; */
    font-size: 70px;
  }
}

@media screen and (max-width: 992px) {
  .about_content h1 {
    font-size: 125px;
  }
  .about_content h5 {
    font-size: 54px;
    margin-block: 30px;
      width: 95%;
      margin-top: 17%;
  }
  /* .about_img1 {
    display: none;
  } */
  .twoParagraphs {
    width: 100%;
  }
}

@media screen and (max-width: 810px) {
  .about_content h1 {
    font-size: 115px;
  }
  .about_content h5 {
    /* font-size: 60px; */
    margin-block: 20px;
  }
  .about_page {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 800px){
  .about_content h5 {
    font-size: 54px;
    margin-block: 30px;
      width: 95%;
      margin-top: 17%;
  }
}

@media screen and (max-width: 767px){
  .aboutMainContent h4 {
    width: 100%;
    font-size: 54px;
}
.twoParagraphs {
  margin-top: 30px;
  display: block;
}
.about_img2 {
  margin-top: 50px
}
.twoParagraphs p {
  margin-bottom: 20px;
}
}
@media screen and (max-width: 710px) {
  .about_content h5 {
    font-size: 45px;
  }
  .about_content h1 {
    font-size: 105px;
  }
  .pattern {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
  /* .about_img2 {
    height: 300px;
  } */
}

@media screen and (max-width: 645px) {
  .about_content h1 {
    font-size: 95px;
  }
  .pattern {
    width: 75px;
    height: 75px;
    border-radius: 75px;
  }
}

@media screen and (max-width: 600px){
  .about_content h5 {
    font-size: 54px;
    width: 95%;
    margin-top: 25%;
}
.about_img1 {
  height: 400px;
}
.aboutMainContent h4 {
  font-size: 44px;
}
}
@media screen and (max-width: 581px) {
  .about_content h1 {
    font-size: 85px;
  }
}
@media screen and (max-width: 575px) {
  
  .aboutMainContent h4 {
    width: 100%;
  }

  .twoParagraphs {
    margin-top: 20px;
    width: 100%;
    flex-wrap: wrap;
  }
  .w100 {
    width: 100%;
  }
  /* .about_img2 {
    height: 250px;
  } */
}

@media screen and (max-width: 520px) {
  .about_content h1 {
    font-size: 80px;
  }
  /* .about_img2 {
    height: 230px;
  } */
  
}
@media screen and (max-width: 490px) {
  .about_content h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 480px){
  .about_content h5 {
    font-size: 46px;
    width: 100%;
}
.aboutMainContent h4 {
  font-size: 38px;
}
}

@media screen and (max-width: 425px){
  .about_img1 {
    height: 300px;
}
.aboutMainContent h4 {
  font-size: 34px;
}
.about_img2 {
  height: 300px;
}
}


@media screen and (max-width: 375px) {
  
  .about_content h5 {
    font-size: 44px;
    margin-top: 35%;
  }
  
}

@media screen and (max-width: 320px) {
  
  .about_content h5 {
    font-size: 44px;
  }
  .aboutMainContent h4 {
    font-size: 32px;
  }
  
}
