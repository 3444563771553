.contactSection {
  margin-block: 20px;
  padding-block: 7%;
  /* background-color: #f6f9fc; */

  /* background: transparent linear-gradient(45deg, #004a0024, rgb(0 74 0 / 20%)); */
  /* border-radius: 20px; */
  /* box-shadow: 5px 5px 6px 0px rgb(39 39 39 / 26%); */
}
.contactSection h5 {
  /* font-size: 35px;
    color: var(--main-color); */
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
}
.mt20 {
  margin-top: 20px;
}
.bgTrans {
  background-color: transparent !important;
}

.contactSection .bigHeading {
  font-weight: 400;
}
.contactSection .smallHeading {
  margin-top: 50px;
  font-weight: 400;
  width: 75%;
}
.aboutBigHeading {
  font-size: 168px;
  font-weight: 400;
  line-height: 1.1;
}

.icon {
}
.contactEmail {
  margin-block: 50px !important;
  display: block;
  text-align: center;
  font-size: 90px;
  border: 2px solid black;
  border-radius: 20px;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 25px;
  position: relative;
  background: white;
}
.contactEmail:after {
  position: absolute;
  content: "";
  background: black;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  top: 10px;
  left: 10px;
  z-index: -1;
}
@media screen and (max-width: 1440px) {
  .aboutBigHeading {
    font-size: 150px;
  }
}

@media screen and (max-width: 1200px) {
  .aboutBigHeading {
    font-size: 130px;
  }
}
@media screen and (max-width: 1024px) {
  .aboutBigHeading {
    font-size: 120px;
  }
  .contactEmail {
    font-size: 80px;
  }
}
@media screen and (max-width: 992px) {
  .aboutBigHeading {
    font-size: 90px;
  }
  .contactEmail {
    font-size: 70px;
    padding: 8px 20px;
    word-break: break-all;
  }
}
@media screen and (max-width: 768px) {
  .aboutBigHeading {
    font-size: 80px;
  }
  .subHeading {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .contactEmail {
    font-size: 60px;
  }
}

@media screen and (max-width: 600px) {
  .aboutBigHeading {
    font-size: 75px;
  }
  .contactEmail {
    font-size: 50px;
  }
  .contactEmail {
    border-radius: 10px;
  }
  .contactEmail:after {
    border-radius: 10px;
    top: 7px;
    left: 7px;
  }
}
@media screen and (max-width: 575px) {
  .aboutBigHeading {
    font-size: 60px;
  }
  .subHeading {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .contactEmail {
    font-size: 40px;
  }
}

@media screen and (max-width: 540px) {
  .aboutBigHeading {
    font-size: 65px;
  }
}

@media screen and (max-width: 480px) {
  .aboutBigHeading {
    font-size: 57px;
  }
  .contactEmail {
    border-radius: 15px;
  }
}
@media screen and (max-width: 425px) {
  .contactEmail {
    font-size: 35px;
  }
  .aboutBigHeading {
    font-size: 50px;
  }
}
@media screen and (max-width: 375px) {
  .aboutBigHeading {
    font-size: 45px;
  }
  .subHeading {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .contactEmail {
    font-size: 30px;
  }
  .contactEmail:after {
    border-radius: 15px;
  }
}

@media screen and (max-width: 320px) {
  .aboutBigHeading {
    font-size: 42px;
  }
  .contactEmail {
    font-size: 28px;
    border-radius: 10px;
  }
  .contactEmail:after {
    border-radius: 10px;
    top: 7px;
    left: 7px;
  }
}
