.bgTrans {
  background-color: transparent !important;
}
.reviewsSection {
  /* background-color: #f6f9fc; */
  padding-block: 30px;
}
.reviewsSection h5 {
  /* font-size: 35px; */
  /* color: var(--text-black-color); */
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
}
.carouselImg {
  width: 100%;
  height: 300px;
}
.carouselImg img {
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
}
