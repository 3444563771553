.faqsSection {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  padding-block: 70px;
  /* min-height: calc(100vh - 65px); */
  background-color: var(--clr-ffffff);
}
/* .faqsSection p {
    color: var(--text-black-color);
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  } */

.bgTrans {
  background-color: transparent !important;
}

.accordionWrapper {
  width: 75%;
  margin: 0 auto;
}

.faqHeading {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 124px;
  margin: 0px;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px){
  .faqHeading {
    font-size: 100px;
}
}

/* @media screen and (max-width: 992px) {
  .faqHeading {
    font-size: 80px;
  }
} */
@media screen and (max-width: 768px) {
  .faqHeading {
    font-size: 80px;
  }
  .accordionWrapper {
    width: 100%;
  }
  .faqsSection {
    padding-block: 30px;
  }
}

@media screen and (max-width: 600px){
.faqHeading {
    font-size: 70px;
}
}
@media screen and (max-width: 575px) {
  .faqHeading {
    font-size: 45px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 540px){
.faqHeading {
    font-size: 55px;
}
}
@media screen and (max-width: 425px) {
  .faqHeading {
    font-size: 40px;
  }
}
@media screen and (max-width: 375px) {
  .faqHeading {
    font-size: 38px;
    margin-bottom: 10px;
  }
}
