.box {
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 35.2px;

  position: relative;
  padding: 14px 20px 14px 28px;
  margin-right: 10px;

  border: 1px solid #d9d9d9 !important;
  color: black !important;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  cursor: pointer;
}
.leftCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.selected {
  background-color: var(--main-color);
}
.bgTrans {
  background-color: transparent !important;
}
.imgDiv {
  width: 100%;
  height: 200px;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.mt20 {
  margin-top: 20px;
}

.section h5 {
  margin: 0px;
  text-align: center;
  margin-bottom: 40px;
}
/* .section {
  background-color: var(--clr-ffffff) !important;
} */

.serviceSection {
  background-color: rgb(0, 0, 0) !important;
  padding-block: 7%;
  overflow: hidden;
}

.serviceSection h5 {
  margin-bottom: 20px;
  color: white;
  font-weight: 400;
}
.serviceSection h1 {
  margin-bottom: 50px;
  color: white;
  font-weight: 400;
  font-size: 100px !important;
}

.serviceSection p {
  color: white;
  width: 75%;
  margin-top: 150px;
}

.servicesList {
  color: white;
  margin-top: 30px;
}
.servicesList h3 {
  font-weight: 400;
}
.servicesList li {
  padding-block: 15px;
}
.servicesList h3 {
  font-size: 45px;
}
.servicesList h3 a {
  color: white;
}

.servNumber {
  font-size: 16px;
  display: inline-flex;
  vertical-align: middle;
  margin-right: 20px;
  background: white;
  color: black;
  width: fit-content;
  padding-inline: 8px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
}

.serviceBtn {
  margin-top: 30px;
}
.servicesList h3:hover {
  transform: translateX(10px);
  transition: transform 250ms ease-in-out;
}
.servicesList h3 {
  transition: transform 250ms ease-in-out;
}

.icon {
  /* animation: rotate 2s infinite; */
  animation-name: rotate;
  animation-duration: 5s;
  animation-delay: 2s;
  animation-play-state: running;
}

@keyframes rotate {
  20% {
    transform: rotate(60deg);
  }
  40% {
    transform: rotate(120deg);
  }
  60% {
    transform: rotate(180deg);
  }
  80% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 1440px) {
  .serviceSection h1 {
    font-size: 90px !important;
  }
}
@media screen and (max-width: 1330px) {
  .serviceSection h1 {
    font-size: 80px !important;
  }
}
@media screen and (max-width: 1200px) {
  .serviceSection h1 {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 1043px) {
  .serviceSection h1 {
    font-size: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .serviceSection h1 {
    font-size: 60px !important;
  }
  .servicesList h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .servNumber {
    margin-right: 10px;
  }
}
@media screen and (max-width: 900px) {
  .serviceSection h1 {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 830px) {
  .serviceSection h1 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .serviceSection h1 {
    margin-bottom: 50px;
    font-size: 70px !important;
  }
  .serviceSection p {
    color: white;
    width: 100%;
    margin-top: 0px;
  }
  .serviceSection h5 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .serviceSection {
    padding-block: 15%;
  }
}
@media screen and (max-width: 575px) {
  .serviceSection h1 {
    margin-bottom: 20px;
    font-size: 60px !important;
  }
  .serviceSection h5 {
    margin-top: 30px;
  }
  .servicesList h3 {
    font-size: 20px;
  }
  .servNumber {
    margin-right: 10px;
  }
}

@media screen and (max-width: 540px) {
  .serviceSection .ant-col-sm-24 {
    width: 100%;
  }
  .servicesList h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 425px) {
  .servicesList h3 {
    font-size: 26px;
  }
  .serviceSection h1 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 375px) {
  .servicesList h3 {
    font-size: 22px;
  }
  .serviceSection h1 {
    font-size: 45px !important;
  }
}

@media screen and (max-width: 320px) {
  .servicesList h3 {
    font-size: 20px;
  }
  .servNumber {
    font-size: 12px;
  }
}
