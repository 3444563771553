$icon-size: 40px;
$border-radius: 0.5; //15% = 0.15, 50% = 0.50 etc.
// $green        : #16A085;
$green: var(--main-color);
$white: #fff;

.footer {
  padding-block: 40px;
  background-color: var(--clr-000000);
  .link {
    cursor: pointer;
    transition: all 0.2s linear;
  }
  .link:hover {
    color: var(--main-color);
  }
  .footer_about{
    width: 75% !important;
  }
  h5 {
    color: var(--clr-ffffff);
  }
  h6 {
    font-size: 22px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: var(--clr-ffffff);
    @media screen and (max-width: 575px) {
      margin-bottom: 10px;
    }
  }
  
  .footerAbout{
    width: 75%;
  }

  ul:not(.services) {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
    li {
      font-size: 16px;
      color: var(--clr-ffffff);

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  ul.services {
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    li {
      font-size: 16px;
      color: var(--clr-ffffff);
      padding: 6px 15px 6px 15px;
      border: 2px solid var(--clr-ffffff);
      border-radius: 100px;
    }
  }
  p {
    color: var(--clr-ffffff);
    margin: 0px;
  }
  .socialIcons {
    display: flex;
    gap: 8px;
    margin-top: 30px;
  }
  .endLine {
    margin-top: 80px;
    border-block: 1px solid var(--main-color);
  }
  .endLine h1 {
    color: var(--main-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 8.3rem;
    text-align: center;
  }
  .spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .spBtw p {
    color: var(--main-color);
  }
  
}
// .container {
//   max-width: 85%;
//   margin: 0 auto;
// }

.icon {
  // background-color: red;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $icon-size;
  height: $icon-size;
  // border-radius: $icon-size*$border-radius;

  border-radius: 50%;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.25s ease;
    border-radius: 50%;
  }
  svg {
    //   position: relative;
    // color: var(--text-black-color);
    color: var(--clr-000000);
    height: $icon-size/2;
    width: $icon-size/2;
    transition: all 0.25s ease;
    z-index: 1;
    vertical-align: middle;
  }
}

.icon-fill {
  user-select: none;

  &::before {
    transition-duration: 0.5s;
    // box-shadow: inset 0 0 0 1px var(--text-black-color);
    // box-shadow: inset 0 0 0 1px var(--text-black-color);
    box-shadow: inset 0 0 0 $icon-size var(--clr-ffffff);
  }
  &:hover {
    svg {
      transition-duration: 0.5s;
      // color: var(--clr-ffffff);
    }
  }
  &:hover::before {
    box-shadow: inset 0 0 0 $icon-size $green;
  }
}

.logo {
  position: relative;
  width: max-content;
}
.logo span {
  position: absolute;
  left: 100%;
  font-size: 16px;
  top: -10px;
}

//   .icon-container{
//     margin-top: 30px;
//     width: 100%;
//     height: $icon-size;
//   }

@media screen and (max-width: 1920px){
  .endLine h1 {
    font-size: 11.5rem !important;
  }
}
@media screen and (max-width: 1600px){
  .endLine h1 {
    font-size: 9.5rem !important;
  }
}
@media screen and (max-width: 1536px){
  .endLine h1 {
    font-size: 9.2rem !important;
  }
}
@media screen and (max-width: 1440px){
  .endLine h1 {
    font-size: 8.45rem !important;
  }
}
@media screen and (max-width: 1400px){
  .endLine h1 {
    font-size: 8.4rem !important;
  }
}
@media screen and (max-width: 1366px){
  .endLine h1 {
    font-size: 8.2rem !important;
  }
}
@media screen and (max-width: 1280px){
  .endLine h1 {
    font-size: 7.7rem !important;
  }
}
@media screen and (max-width: 1024px){
  .endLine h1 {
    font-size: 6.15rem !important;
  }
}
@media screen and (max-width: 992px){
  .endLine h1 {
    font-size: 5.95rem !important;
  }
}
@media screen and (max-width: 800px){
  .endLine h1 {
    font-size: 4.75rem !important;
  }
  .spBtw p {
    font-size: 12px;
}
}
@media screen and (max-width: 600px){
  .endLine h1 {
    font-size: 3.75rem !important;
  }
}
@media screen and (max-width: 540px){
  .endLine h1 {
    font-size: 3.4rem !important;
  }
}
@media screen and (max-width: 480px){
  .endLine h1 {
    font-size: 3.0rem !important;
  }
}
@media screen and (max-width: 425px){
  .endLine h1 {
    font-size: 2.7rem !important;
  }
}
@media screen and (max-width: 375px){
  .endLine h1 {
    font-size: 2.42rem !important;
  }
  .spBtw p {
    width: 100%;
    text-align: center;
}
.spBtw {
    flex-wrap: wrap
}
}
@media screen and (max-width: 360px){
  .endLine h1 {
    font-size: 2.32rem !important;
  }
}
@media screen and (max-width: 360px){
  .endLine h1 {
    font-size: 2.02rem !important;
  }
}