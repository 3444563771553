.card {
  border-radius: 20px;
  position: relative;
}
.card::after {
  position: absolute;
  content: " ";
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: inherit;
}
.imgDiv {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.icon{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: var(--clr-ffffff);
    cursor: pointer;
    z-index: 3;
    transition: transform 250ms ease-in-out;
}
.details{
    position: absolute;
    bottom: 10px;
    left: 20px;
    z-index: 3;
 
}
.details h6{
    color: var(--clr-ffffff);
    font-size: 20px;
    text-transform: uppercase;
}
.details p{
    font-size: 13px;
    color: var(--clr-ffffff);
}

.imgZoom{
  overflow: hidden;
}
.imgZoom img{
  transform-origin: center !important;
  transition: transform 250ms ease-in-out !important;
  object-fit: cover;
}
.card:hover .imgZoom img{
  transform: scale(1.05) !important;
  cursor: pointer;
}
.card:hover .icon{
  transform: scale(1.5);
  transform-origin: center;
  transition: transform 250ms ease-in-out;
}

