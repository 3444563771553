.bgTrans {
  background-color: transparent !important;
}
.section {
  background-color: var(--clr-ffffff) !important;
}
.section h5 {
  /* font-size: 35px;
    color: var(--main-color); */
  margin: 0px;
  margin-bottom: 10px;
}
.imgDiv {
  width: 100%;
  height: 100%;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.twoCardBox {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100% !important;
}
.mt20 {
  margin-top: 20px;
}

.BigCard .details h6 {
  font-size: 44px !important;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 10px;
}

.aboutBigHeading {
  font-size: 150px;
  font-weight: 400;
  line-height: 1.1;
}

.subHeading {
  font-size: 34px;
  margin-bottom: 80px;
}

.portfolioSection {
  padding-block: 7%;
}


@media screen and (max-width: 1024px){
  .aboutBigHeading {
    font-size: 110px;
}
}
/* @media screen and (max-width: 992px) {
  .aboutBigHeading {
    font-size: 80px;
  }
} */
@media screen and (max-width: 768px) {
  .aboutBigHeading {
    font-size: 90px;
  }
  .subHeading {
    font-size: 30px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 600px){
  .aboutBigHeading {
    font-size: 70px;
}
.portfolioSection{
  padding-block: 15%;
}
}
@media screen and (max-width: 575px) {
  .aboutBigHeading {
    font-size: 60px;
  }
  .subHeading {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px){
  .aboutBigHeading {
    font-size: 55px;
}
}
@media screen and (max-width: 425px){
  .aboutBigHeading {
    font-size: 50px;
}
}
@media screen and (max-width: 375px) {
  .aboutBigHeading {
    font-size: 40px;
  }
  .subHeading {
    font-size: 25px;
    margin-bottom: 20px;
  }
}
