.contactSection {
  padding: 50px 30px;
  /* background-color: #f6f9fc; */

  /* background: transparent linear-gradient(45deg, #004a0024, rgb(0 74 0 / 20%)); */
  /* border-radius: 20px; */
  /* box-shadow: 5px 5px 6px 0px rgb(39 39 39 / 26%); */
}
.contactSection h5 {
  /* font-size: 35px;
    color: var(--main-color); */
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
}
.mt20 {
  margin-top: 20px;
}
.bgTrans {
  background-color: transparent !important;
}

.contactSection .bigHeading {
  font-weight: 400;
}
.contactSection .smallHeading {
  margin-top: 50px;
  font-weight: 400;
  width: 75%;
}
.aboutBigHeading {
  font-size: 168px;
  font-weight: 400;
  line-height: 1.1;
}

.icon {
  font-size: 36px;
}
.contactEmail {
  margin-block: 50px !important;
  display: block;
  text-align: center;
  font-size: 90px;
  border: 2px solid black;
  border-radius: 20px;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 25px;
  position: relative;
  background: white;
}
.contactEmail:after {
  position: absolute;
  content: "";
  background: black;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  top: 10px;
  left: 10px;
  z-index: -1;
}

.socialLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 50px;
}

.socialLink {
  border: 1px solid var(--clr-000000);
  border-radius: 50px;
  padding: 5px 10px;
  color: var(--clr-ffffff);
  background: var(--clr-000000);
  transition: all 250ms ease-in-out;
}

.socialLink:hover {
  border: 1px solid var(--clr-000000);
  border-radius: 50px;
  padding: 5px 10px;
  color: var(--clr-000000);
  background: var(--clr-ffffff);
  transition: all 250ms ease-in-out;
}

.contactHeader {
  margin-bottom: 50px;
}

.contactHeader .aboutBigHeading {
  margin-top: 15%;
}

.email_number {
  margin-bottom: 50px;
}
:is(.email, .number) {
  font-weight: 400;
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.email {
  margin-bottom: 10px;
}

.pattern {
  --s: 25vmin;
  --p: calc(var(--s) / 2);
  --c1: rgb(220, 255, 124);
  --c2: rgb(120, 255, 30);
  --c3: white;
  --bg: var(--c3);
  --d: 4000ms;
  --e: cubic-bezier(0.76, 0, 0.24, 1);

  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--bg);
  background-image: linear-gradient(45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(-45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--c2) 75%),
    linear-gradient(-45deg, transparent 75%, var(--c2) 75%);
  background-size: var(--s) var(--s);
  background-position: calc(var(--p) * 1) calc(var(--p) * 0),
    calc(var(--p) * -1) calc(var(--p) * 1),
    calc(var(--p) * 1) calc(var(--p) * -1),
    calc(var(--p) * -1) calc(var(--p) * 0);
  animation: color var(--d) var(--e) infinite,
    position var(--d) var(--e) infinite;
}

.contactImg1 {
  margin-bottom: 15%;
}
.contact_img1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contact_img1 {
  width: 100%;
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
}

@keyframes color {
  0%,
  25% {
    --bg: var(--c3);
  }
  26%,
  50% {
    --bg: var(--c1);
  }
  51%,
  75% {
    --bg: var(--c3);
  }
  76%,
  100% {
    --bg: var(--c2);
  }
}

@keyframes position {
  0% {
    background-position: calc(var(--p) * 1) calc(var(--p) * 0),
      calc(var(--p) * -1) calc(var(--p) * 1),
      calc(var(--p) * 1) calc(var(--p) * -1),
      calc(var(--p) * -1) calc(var(--p) * 0);
  }
  25% {
    background-position: calc(var(--p) * 1) calc(var(--p) * 4),
      calc(var(--p) * -1) calc(var(--p) * 5),
      calc(var(--p) * 1) calc(var(--p) * 3),
      calc(var(--p) * -1) calc(var(--p) * 4);
  }
  50% {
    background-position: calc(var(--p) * 3) calc(var(--p) * 8),
      calc(var(--p) * -3) calc(var(--p) * 9),
      calc(var(--p) * 2) calc(var(--p) * 7),
      calc(var(--p) * -2) calc(var(--p) * 8);
  }
  75% {
    background-position: calc(var(--p) * 3) calc(var(--p) * 12),
      calc(var(--p) * -3) calc(var(--p) * 13),
      calc(var(--p) * 2) calc(var(--p) * 11),
      calc(var(--p) * -2) calc(var(--p) * 12);
  }
  100% {
    background-position: calc(var(--p) * 5) calc(var(--p) * 16),
      calc(var(--p) * -5) calc(var(--p) * 17),
      calc(var(--p) * 5) calc(var(--p) * 15),
      calc(var(--p) * -5) calc(var(--p) * 16);
  }
}

@media screen and (max-width: 1024px) {
  .aboutBigHeading {
    font-size: 135px;
  }
  .contactEmail {
    font-size: 75px;
  }
}

@media screen and (max-width: 992px) {
  .aboutBigHeading {
    font-size: 130px;
  }
}

@media screen and (max-width: 800px) {
  .aboutBigHeading {
    font-size: 108px;
  }
  .contactEmail {
    font-size: 65px;
  }
  .contactSection div {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    width: 100% !important;
  }
  .email_number {
    margin-top: 100px;
  }
  .contactSection {
    padding-bottom: 80px;
  }
  .contact_img1 {
    height: 400px;
}
}

@media screen and (max-width: 768px) {
  .aboutBigHeading {
    font-size: 106px;
  }
}

@media screen and (max-width: 600px) {
  .contactEmail {
    font-size: 50px;
    border-radius: 10px;
  }
  .contactEmail:after {
    border-radius: 10px;
  }
  .aboutBigHeading {
    font-size: 85px;
  }
  .contactSection .smallHeading {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .aboutBigHeading {
    font-size: 75px;
  }
  .contactEmail {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  .aboutBigHeading {
    font-size: 64px;
  }
  .contactEmail {
    font-size: 35px;
  }
}
@media screen and (max-width: 425px) {
  .aboutBigHeading {
    font-size: 58px;
  }
  .contactEmail {
    font-size: 35px;
  }
  .contact_img1 {
    height: 300px;
}
}

@media screen and (max-width: 375px) {
  .aboutBigHeading {
    font-size: 100px;
    margin-top: 30% !important;
  }
  .contactEmail {
    font-size: 30px;
  }
  .contactEmail:after {
    top: 8px;
    left: 8px;
  }
  .contactSection {
    padding-inline: 20px;
  }
}

@media screen and (max-width: 320px) {
  .contactEmail {
    font-size: 25px;
  }
}
